<template>
<CContainer>
  <CRow>
      <CCol col="12">
        <CCard>
          <CCardHeader>
            <h3>Grupos</h3>
          </CCardHeader>
        </CCard>
      </CCol>
   </CRow>
   <CRow>
      <CCol col="6">
         <CCard>
          <CCardHeader>
            <h5>Crear Grupo</h5>
          </CCardHeader>
          <CCardBody>
            <CForm validate @submit.prevent="crearnuevoGrupo($event)">
               <CInput
                  label="Nombre del Grupo"
                  placeholder="Nombre del Grupo"
                  autocomplete="off"
                  minlength="3"
                  required
                  was-validated
                  v-model="nombre_grupo"
                />
                <hr>
                <CButton size="sm" color="danger" type="submit" v-if="$can('grupos_sms.create')">
                    Crear Grupo
                </CButton>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol col="6">
        <CCard>
          <CCardHeader>
            <h5>Reporte Grafico</h5>
          </CCardHeader>
          <CCardBody>
                
                <CCardBody>
                    <CChartPie
                      :datasets="defaultDatasets"
                      :labels="labelsChart"
                    />
                </CCardBody>
          </CCardBody>
        </CCard>
      </CCol>
   </CRow>
     <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
           <CRow class="justify-content-center">
              <CCol col="6">
                <h5 class="text-center">Cantidad de grupos creados: {{items.length}}</h5>
              </CCol>
           </CRow>
        </CCardHeader>
        <CCardBody>
          <CDataTable
            hover
            striped
            :items="items"
            :fields="fields"
            :items-per-page="10"
            :pagination="{ doubleArrows: false, align: 'center'}"
            column-filter
            @page-change="pageChange"
          >
          <template #cantidad="{item, index}">
              <td>
             {{$formatterNumeroTipoCreditos(item.cantidad)}}
             </td>
           </template>
          <template #created_at="{item}">
              <td>
                 {{formatoFecha(item.created_at)}}
              </td>
          </template>
          <template #acciones="{item}">
            <td>
              <router-link :to="'/grupos/integrantes/'+item.id_grupo">
                  <CButton size="sm" color="info" v-if="$can('grupos_sms.vercontactos')">
                    Ver Contactos
                  </CButton>
              </router-link>
            </td>
            <td>
              <CButton size="sm" color="danger" @click="abrirmodalEliminarGrupo(item)" v-if="$can('grupos_sms.deletegrupos')">
                    Eliminar Grupo
              </CButton>
            </td>
          </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
  <div>
          <CToaster :autohide="5000" class="d-inline-block">
             <template v-for="toast in mostrarNotificacion">
                <CToast
                  :key="'toast'+toast"
                  :show="true"
                  color="success"
                  header="Notificacion Exitosa"
                >
                  {{mensaje_success}}
                </CToast>
              </template>   
              <template v-for="toast1 in mostrarNotificacionAlerta">
                <CToast
                  :key="'toast1'+toast1"
                  :show="true"
                  color="warning"
                  header="Notificacion Alerta"
                >
                  {{mensaje_warning}}
                </CToast>
              </template>
              <template v-for="toast2 in mostrarNotificacionError">
                <CToast
                  :key="'toast2'+toast2"
                  :show="true"
                  color="danger"
                  header="Notificacion Alerta"
                >
                  Error Comunicarse Con Soporte
                </CToast>
              </template>  
          </CToaster>
          <CModal
            :show.sync="modal_eliminar_grupo"
            :no-close-on-backdrop="true"
            :centered="true"
            title="Modal title 2"
            size="sm"
            color="dark"
          >
            <CRow>
              <CCol col="12">
                Usted esta Apunto de Eliminar el Grupo : <strong>{{form_eliminar_grupo.nombre}}</strong>
              </CCol>
            </CRow>
            <template #header>
              <h6 class="modal-title">Eliminar Grupo</h6>
              <CButtonClose @click="modal_eliminar_grupo = false" class="text-white"/>
            </template>
            <template #footer>
              <CButton @click="modal_eliminar_grupo = false" color="danger">Cancel</CButton>
              <CButton  color="success" @click="eliminarGrupoPorId()" v-if="$can('grupos_sms.deletegruposid')">Aceptar</CButton>
            </template>
          </CModal>
  </div>
</CContainer>

</template>


<script>
import EventBus from '../../modules/bus';
import { CChartPie } from '@coreui/vue-chartjs'
export default {
  name: 'Grupos',
  components: { CChartPie },
  data () {
    return {
      items: [],
      fields: [
        { key: 'nombre_grupo', label: 'Nombre', _classes: 'font-weight-bold' },
        { key: 'cantidad',label:'Cantidad de Integrantes' },
        { key: 'created_at',label:'Fecha Creado' },
        { key: 'acciones',label:'',
          _style: { width: '1%' },
          sorter: false,
          filter: false 
        }
      ],
      activePage: 1,
      collapseDuration: 0,
      nombre_grupo:'',
      mostrarNotificacion: 0,
      mostrarNotificacionAlerta: 0,
      mostrarNotificacionError:0,
      mensaje_warning:'Debe Completar Los Datos Vacio',
      mensaje_success:'Grupo Creado Correctamente.',
      modal_eliminar_grupo:false,
      form_eliminar_grupo:{
        nombre:'',
        id:'',
      },
      labelsChart:[],
      dataChart:[],

    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    }
  },
  methods: {
    getBadge (status) {
      switch (status) {
        case 1: return 'success'
        case 0: return 'danger'
      }
    },
    rowClicked (item, index) {
      this.$router.push({path: `users/${index + 1}`})
    },
    pageChange (val) {
      this.$router.push({ query: { page: val }})
    },
    getGrupos(){
        this.$store.dispatch('auth/loading_http','visible');
        this.$http.get('/sms/grupos').then(response => {
             this.items = response.data;
             this.items.map((item,id) => {  
                if (id%2 == 0) {
                  item['_classes'] = 'table-now'; 
                }
                return item;
              })
             //this.items = this.items.map((item, id) => { return {...item, id}})

             this.labelsChart = this.items.map((item) => { return item.nombre_grupo})
             this.dataChart = this.items.map((item) => { return item.cantidad  })
        }).catch(()=>{
             
        }).finally(() =>this.$store.dispatch('auth/loading_http','hidden'));
    },
    toggleDetails (item) {
      this.$set(this.items[item.id], '_toggled', !item._toggled)
      this.collapseDuration = 300
      this.$nextTick(() => { this.collapseDuration = 0})
    },
    crearnuevoGrupo(evt){
      evt = (evt) ? evt : window.event;
      evt.target.disabled = true;
      this.$store.dispatch('auth/loading_http','visible');
      this.$http.post('/sms/grupos',{
        nombre:this.nombre_grupo
      }).then(response =>{
        let data = response.data;
        if (data == "OK") {
            this.mensaje_success='Grupo Creado Correctamente.'
            evt.target.disabled = false;
            this.nombre_grupo = '';
            this.mostrarNotificacion ++;
            this.getGrupos();
        }else{
              let mensajeError = '';
              let dataError = response.data;
              for (const key in dataError) {
                if (Object.hasOwnProperty.call(dataError, key)) {
                  const element = dataError[key];
                  //mensajeError += `${element.mensaje} \n`
                  for (const iterator of element) {
                    mensajeError += `${iterator} \n`
                  }
                }
              }
              this.mensaje_warning = mensajeError
              this.mostrarNotificacionAlerta += 1;
              evt.target.disabled = false;
        }
      }).catch(error=>{this.mostrarNotificacionError ++;evt.target.disabled = false;}).finally(() =>this.$store.dispatch('auth/loading_http','hidden'))
    },
    eliminarGrupoPorId(){
      this.modal_eliminar_grupo = false
      this.$store.dispatch('auth/loading_http','visible');
      this.$http.delete('/sms/grupos/'+this.form_eliminar_grupo.id).then(response =>{
        let data = response.data;
        if (data == "OK") {
            let nombre = this.form_eliminar_grupo.nombre;
            this.form_eliminar_grupo.id = ''
            this.form_eliminar_grupo.nombre = ''
            this.mostrarNotificacion ++;
            this.getGrupos();
            this.mensaje_success=`Grupo ${nombre} Eliminado Correctamente.`,
            setTimeout(() => {this.mensaje_success='Grupo Creado Correctamente.'},6000)
        }else{
              let mensajeError = '';
              let dataError = response.data;
              for (const key in dataError) {
                if (Object.hasOwnProperty.call(dataError, key)) {
                  const element = dataError[key];
                  //mensajeError += `${element.mensaje} \n`
                  for (const iterator of element) {
                    mensajeError += `${iterator} \n`
                  }
                }
              }
              this.mensaje_warning = mensajeError
              this.mostrarNotificacionAlerta += 1;
        }
      }).catch(error=>{this.mostrarNotificacionError ++;}).finally(() =>this.$store.dispatch('auth/loading_http','hidden'))
    },
    formatoFecha(fecha){
      let fechaformato = fecha.split('.')[0];
      return fechaformato.replace('T',' ');
    },
    abrirmodalEliminarGrupo(item){
      console.log(item);
      this.modal_eliminar_grupo = true;
      this.form_eliminar_grupo.nombre = item.nombre_grupo;
      this.form_eliminar_grupo.id = item.id_grupo;
    }
  },
  mounted(){
    setTimeout(() => {
      this.getGrupos();
    }, 100);
  },
  computed: {
    defaultDatasets () {
      return [
        {
          backgroundColor: [
            '#41B883',
            '#E46651',
            '#00D8FF',
            '#DD1B16'
          ],
          data: this.dataChart
        }
      ]
    }
  }
}
</script>
